import ArrowDownIcon from '@apps-orangefi/assets/images/arrow-narrow-down.svg'
import ArrowUpIcon from '@apps-orangefi/assets/images/arrow-narrow-up.svg'
import SykIcon from '@apps-orangefi/assets/images/tokens/syk.svg'
import XSykIcon from '@apps-orangefi/assets/images/tokens/xsyk.svg'
import { BN } from '@apps-orangefi/lib'
import { VaultReward } from '@apps-orangefi/lib/types'
import { numberWithShortScale } from '@apps-orangefi/lib/utils'
import { Platform, Button } from '@apps-orangefi/ui/atoms'
import { TokenPairBySymbol } from '@apps-orangefi/ui/molecules'
import { RewardTableRowSkeleton } from '@apps-orangefi/ui/molecules/skeletons'
import {
  SortDirection,
  createColumnHelper,
  flexRender,
  getCoreRowModel,
  getSortedRowModel,
  getFilteredRowModel,
  useReactTable,
} from '@tanstack/react-table'
import { isEqual } from 'lodash'
import Image from 'next/image'
import { useRouter } from 'next/router'
import { useCallback, useEffect, useMemo, useState } from 'react'
import Skeleton from 'react-loading-skeleton'

type Props = {
  vaultRewards: VaultReward[]
  totalClaimableAmountUsd: BN
  fetching: boolean
  onClaimAll: () => void
  isClaimable: boolean
}

const columnHelper = createColumnHelper<VaultReward>()

const columns = [
  columnHelper.accessor('pairName', {
    header: 'PAIR',
    cell: props => {
      return (
        <div className="flex flex-row items-center">
          <TokenPairBySymbol
            baseTokenSymbol={props.row.original.baseTokenSymbol}
            quoteTokenSymbol={props.row.original.quoteTokenSymbol}
            size={24}
          />
          <div className="ml-7 type-sm-semibold">{props.getValue()}</div>
        </div>
      )
    },
    sortingFn: 'alphanumeric',
  }),
  columnHelper.accessor('amm', {
    header: 'DERIVATIVES / DEX',
    cell: props => {
      return (
        <div className="flex flex-row justify-between relative">
          <Platform
            amm={props.row.original.amm}
            derivative={props.row.original.derivative}
            size={24}
            noText
          />
        </div>
      )
    },
    enableSorting: false,
  }),
  // TODO: fix accessor name
  columnHelper.accessor('vaultAddress', {
    header: 'TOKEN',
    cell: () => (
      <div className="flex flex-col justify-between relative pr-4">
        <div className="flex flex-row">
          <Image src={XSykIcon} width={24} height={24} alt="right-arrow" />
          <span className="ml-2 type-sm-semibold text-white">xSYK</span>
        </div>
        <div className="h-0 w-full border-t border-dashed border-gray-750 my-1.5"></div>
        <div className="flex flex-row">
          <Image src={SykIcon} width={24} height={24} alt="right-arrow" />
          <span className="ml-2 type-sm-semibold text-white">SYK</span>
        </div>
      </div>
    ),
    enableSorting: false,
  }),
  columnHelper.accessor('rewards', {
    header: 'CLAIMABLE',
    cell: props => {
      const xSyk = props.row.original.rewards.find(reward => reward.symbol === 'xSYK')
      const syk = props.row.original.rewards.find(reward => reward.symbol === 'SYK')
      return (
        <div className="flex flex-col justify-between relative pr-4 type-sm-semibold text-white">
          <div className="flex flex-row">
            <span className="font-mono mr-1">
              {xSyk?.amount ? numberWithShortScale(xSyk.amount, 2) : '-'}{' '}
            </span>
            xSYK
            <span className="ml-1 font-mono">
              {xSyk?.amountUsd ? `($${numberWithShortScale(xSyk.amountUsd, 2)})` : ''}
            </span>
          </div>
          <div className="h-0 w-full border-t border-dashed border-gray-750 my-1.5"></div>
          <div className="flex flex-row">
            <span className="font-mono mr-1">
              {syk?.amount ? numberWithShortScale(syk?.amount, 2) : '-'}
            </span>
            SYK
            <span className="ml-1 font-mono">
              {syk?.amountUsd ? `($${numberWithShortScale(syk.amountUsd, 2)})` : ''}
            </span>
          </div>
        </div>
      )
    },
    enableSorting: false,
  }),
]

export const RewardTable = ({
  vaultRewards,
  totalClaimableAmountUsd,
  fetching,
  onClaimAll,
  isClaimable,
}: Props) => {
  const router = useRouter()
  const table = useReactTable({
    data: vaultRewards,
    columns,
    getCoreRowModel: getCoreRowModel(),
    getSortedRowModel: getSortedRowModel(),
    getFilteredRowModel: getFilteredRowModel(),
    initialState: {
      sorting: [
        {
          id: 'pairName',
          desc: true, // sort by tvl in descending order by default
        },
      ],
    },
  })
  const sortArrows = useMemo(() => {
    return {
      asc: <Image src={ArrowUpIcon} width={16} height={16} alt="arrow-up" />,
      desc: <Image src={ArrowDownIcon} width={16} height={16} alt="arrow-down" />,
    }
  }, [])

  const onClickLink = useCallback((vaultAddress: string) => {
    router.push(`/arbitrum/${vaultAddress}`)
  }, [])

  const isEnabled = useMemo(() => {
    return isClaimable && !fetching && totalClaimableAmountUsd.gt(0)
  }, [isClaimable, fetching, totalClaimableAmountUsd])

  if (vaultRewards.length === 0) return <></>
  return (
    <div className="mt-10">
      <div className="type-2xl-semibold text-white mb-6">Rewards</div>
      <div className="flex flex-col sm:flex-row mt-6">
        <div className="flex flex-col w-full md:gap-x-8 mt-3 sm:mt-0">
          <table className="w-full border-separate border-spacing-y-0.5 table-auto">
            <thead className="hidden sm:table-header-group">
              {table.getHeaderGroups().map(headerGroup => (
                <tr key={headerGroup.id} className="bg-gray-800 text-gray-250 type-sm-caption">
                  {headerGroup.headers.map(header =>
                    header.id === 'productType' ? null : (
                      <th
                        key={header.id}
                        onClick={header.column.getToggleSortingHandler()}
                        className={`py-3 text-start first:rounded-tl-2xl first:pl-5 last:rounded-tr-2xl last:pr-5 first:rounded-bl last:rounded-br
                      ${header.column.getCanSort() ? 'cursor-pointer select-none' : ''}`}
                        style={{ width: `${header.getSize()}px` }}
                      >
                        <div className="flex flex-row items-center">
                          <span>
                            {header.isPlaceholder
                              ? null
                              : flexRender(header.column.columnDef.header, header.getContext())}
                          </span>
                          <span className="ml-2">
                            {sortArrows[header.column.getIsSorted() as SortDirection]}
                          </span>
                        </div>
                      </th>
                    )
                  )}
                </tr>
              ))}
            </thead>
            <tbody className="flex flex-col gap-2 sm:table-row-group">
              {fetching ? (
                <>
                  <RewardTableRowSkeleton />
                  <RewardTableRowSkeleton />
                </>
              ) : (
                <>
                  {table.getRowModel().rows.map(row => (
                    <tr
                      key={row.id}
                      className="bg-gray-850 hover:cursor-pointer flex flex-row flex-wrap items-center sm:table-row sm:product-list-item p-3 sm:p-0 rounded-lg sm:rounded-none"
                      onClick={e => {
                        if (e.ctrlKey || e.metaKey) {
                          window.open(`/arbitrum/${row.original.vaultAddress}`, '_blank')
                        } else {
                          onClickLink(row.original.vaultAddress)
                        }
                      }}
                    >
                      {row.getVisibleCells().map(cell =>
                        cell.column.id === 'productType' ? null : (
                          <td
                            key={cell.id}
                            className={`block sm:table-cell sm:first:pl-5 sm:last:pr-5 py-1 sm:py-3 sm:first:rounded-l sm:last:rounded-r`}
                            style={{ width: `${cell.column.getSize()}px` }}
                          >
                            {flexRender(cell.column.columnDef.cell, cell.getContext())}
                          </td>
                        )
                      )}
                    </tr>
                  ))}
                </>
              )}
            </tbody>
          </table>
          <div className="flex flex-row items-center justify-between bg-gray-850 rounded-t rounded-b-xl w-[400px] self-end px-5 py-2.5">
            {fetching ? (
              <>
                <Skeleton width={130} height={20} />
              </>
            ) : (
              <>
                <div className="type-sm-caption text-gray-250">
                  TOTAL:
                  <span className="ml-2 font-mono text-white">
                    ${numberWithShortScale(totalClaimableAmountUsd, 2)}
                  </span>
                </div>
              </>
            )}
            <Button
              label="Claim all"
              disabled={!isEnabled}
              onSubmit={onClaimAll}
              className="px-3 py-2 rounded-xl"
            />
          </div>
        </div>
      </div>
    </div>
  )
}
